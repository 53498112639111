<template>
  <v-sheet class="custom-timetable">
    <v-toolbar flat class="calendar-header flex-md-column" height="auto">
      <v-toolbar-items class="my-2 toolbar-left-items flex-grow-1 align-center flex-sm-row flex-column">
        <div class="d-flex align-center">
          <v-btn
            text
            depressed
            icon
            class="my-auto mx-3"
            @click="_onDateControlClick('prev')"
            :class="{ 'pointer-loading': loading }"
          >
            <v-icon color="colorWhite">{{ icon.mdiChevronLeft }}</v-icon>
          </v-btn>
          <v-btn
            text
            depressed
            icon
            class="my-auto mx-3"
            @click="_onDateControlClick('next')"
            :class="{ 'pointer-loading': loading }"
          >
            <v-icon color="colorWhite">{{ icon.mdiChevronRight }}</v-icon>
          </v-btn>
        </div>

        <v-toolbar-title class="colorWhite--text ml-sm-6 subtitle-2">{{ dateDisplayText }}</v-toolbar-title>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items class="my-2 toolbar-right-items">
        <v-btn
          @click="_onCalendarTypeChanged('day')"
          class="my-auto ml-3"
          :class="{ accent: calendarType === 'day', 'pointer-loading': loading }"
          :color="calendarType === 'day' ? 'colorBlack' : 'colorWhite'"
          outlined
        >{{ $t('calendar.control.day') }}</v-btn>
        <v-btn
          @click="_onCalendarTypeChanged('week')"
          class="my-auto ml-3"
          :class="{ accent: calendarType === 'week', 'pointer-loading': loading }"
          :color="calendarType === 'week' ? 'colorBlack' : 'colorWhite'"
          outlined
        >{{ $t('calendar.control.week') }}</v-btn>
        <v-btn
          @click="_onCalendarTypeChanged('month')"
          class="my-auto ml-3"
          :class="{ accent: calendarType === 'month', 'pointer-loading': loading }"
          :color="calendarType === 'month' ? 'colorBlack' : 'colorWhite'"
          outlined
        >{{ $t('calendar.control.month') }}</v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-container class="custom-timetable__body pa-0" v-if="loading">
      <div class="text-center colorBlack--text d-flex align-center justify-center" style="min-height: 180px;">
        {{ $t('table.processing') }}
      </div>
    </v-container>

    <v-container fluid v-if="!loading && calendarType === 'day'" class="custom-timetable__body pa-0">
      <div class="d-flex align-center" v-if="!displayEmpty">
        <template v-for="time in timeList">
          <span class="custom-timetable__col custom-timetable__col__header">{{ time.start_time }} - {{ time.end_time }}</span>
        </template>
      </div>
      <div class="d-flex" v-if="!displayEmpty">
        <template v-for="time in dailyTableData">
          <div class="custom-timetable__col">
            <template v-for="item in time">
              <v-card class="mb-4 custom-timetable-card" @click="_navToDetails(item.id)">
                <v-card-text class="pa-0 ma-0">
                  <div
                    class="d-flex align-start justify-space-between custom-timetable-card__top"
                    :style="{
                      background: item.color,
                      color: (item.color === '#A7FF00' || item.color === '#2ED8F7') ? '#3B3B3B' : '#ffffff'
                    }"
                  >
                    <span>{{ item.tutor }}</span>
                    <span>{{ $t('calendar.currentPerMaxStudent', { current: item.current_student_num, max: item.max_student } ) }}</span>
                  </div>
                  <div class="custom-timetable-card__bot">
                    <div class="course-name">{{ item.course_name }}</div>
                    <ul class="course-info-list">
                      <li>
                        <v-icon color="colorBlack" size="12" class="fi fi-rr-graduation-cap mr-2"></v-icon>{{ item.grade | formatGrade }}
                      </li>
                      <li>
                        <v-icon color="colorBlack" size="12" class="fi fi-rr-door-open mr-2"></v-icon>{{ item.classroom }}
                      </li>
                    </ul>

                    <ul class="student-attendance-list" v-if="$validate.DataValid(item.student_data)">
                      <li v-for="data in item.student_data" :key="data.id">
                        <div class="attend-check" :class="data.attendance"></div>
                        <div
                          class="gender-dot"
                          :class="data.gender === 'M' ? 'gender-m' : data.gender === 'F' ? 'gender-f' : ''"
                        ></div>
                        <span>{{ data.name }}</span>
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </div>
        </template>
      </div>
      <div v-else class="text-center colorBlack--text d-flex align-center justify-center" style="min-height: 180px;">
        {{ $t('calendar.noLesson') }}
      </div>
    </v-container>
    <v-container fluid v-else-if="!loading && calendarType === 'week'" class="custom-timetable__body pa-0">
      <div class="d-flex align-center" v-if="!displayEmpty">
        <template v-for="date in datesList">
          <span class="custom-timetable__col custom-timetable__col__header">
            <div class="caption mb-1">{{ _formatWeekday(date) }}</div>
            <div class="body-2">{{ _formateShortDay(date) }}</div>
          </span>
        </template>
      </div>

      <div class="d-flex" v-if="!displayEmpty">
        <template v-for="date in weeklyTableData">
          <div class="custom-timetable__col">
            <template v-for="item in date">
              <v-card class="mb-4 custom-timetable-card" @click="_navToDetails(item.id)">
                <v-card-text class="pa-0 ma-0">
                  <div class="custom-timetable-card__top" 
                    :style="{ 
                      background: item.color,
                      color: (item.color === '#A7FF00' || item.color === '#2ED8F7') ? '#3B3B3B' : '#ffffff'
                    }"
                  >
                    <div class="d-flex align-start justify-space-between">
                      <span>{{ item.tutor }}</span>
                      <span>{{ $t('calendar.currentPerMaxStudent', { current: item.current_student_num, max: item.max_student } ) }}</span>
                    </div>
                    <span>{{ item.start_time }}-{{ item.end_time }}</span>
                  </div>

                  <div class="custom-timetable-card__bot">
                    <div class="course-name">{{ item.course_name }}</div>
                    <ul class="course-info-list">
                      <li>
                        <v-icon color="colorBlack" size="12" class="fi fi-rr-graduation-cap mr-2"></v-icon>
                        {{ item.grade | formatGrade }}
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </div>
        </template>
      </div>
      <div v-else class="text-center colorBlack--text d-flex align-center justify-center" style="min-height: 180px;">
        {{ $t('calendar.noLesson') }}
      </div>
    </v-container>
    <v-container fluid v-else-if="!loading && calendarType === 'month'" class="custom-timetable__body pa-0">
      <v-calendar
        type="month"
        :show-month-on-first="false"
        :weekday-format="_displayCalendarWeekday"
        :day-format="_displayCalendarDay"
        :events="monthTableData"
        @click:date="_onMonthCalendarDateClick"
        ref="monthCalendar"
        v-model="calendarValue"
        :locale="locale"
      >
        <template v-slot:event="{ event }">
          <v-card class="custom-timetable-card" @click="_navToDetails(event.id)">
            <v-card-text class="pa-0 ma-0">
              <div class="custom-timetable-card__top" 
                :style="{ 
                  background: event.color,
                  color: (event.color === '#A7FF00' || event.color === '#2ED8F7') ? '#3B3B3B' : '#ffffff'
                }"
              >
                <div class="d-flex align-start justify-space-between">
                  <span>{{ event.tutor }}</span>
                  <span>{{ $t('calendar.currentPerMaxStudent', { current: event.current_student_num, max: event.max_student } ) }}</span>
                </div>
                <span>{{ event.start_time }}-{{ event.end_time }}</span>
              </div>

              <div class="custom-timetable-card__bot">
                <div class="course-name">{{ event.course_name }}</div>
                <ul class="course-info-list">
                  <li>
                    <v-icon color="colorBlack" size="12" class="fi fi-rr-graduation-cap mr-2"></v-icon>
                    {{ event.grade | formatGrade }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-calendar>
    </v-container>
  </v-sheet>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { cannotAccessList } from '@/assets/cannotAccessList'

export default {
  name: 'CustomTimetable',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    timetableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    locale() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'en'
        case 'cn':
          return 'zh-CN'
        default:
          return 'zh-HK'
      }
    },
    dateDisplayText() {
      if (this.$validate.DataValid(this.datesList)) {
        if (this.calendarType === 'week') {
          return `${this.$d(new Date(this.datesList[0]), 'fullDate')}${this.$i18n.locale === 'en' ? ' to ' : '至'}${this.$d(new Date(this.datesList[this.datesList.length - 1]), 'fullDate')}`
        } else if (this.calendarType === 'month') {
          return this.$d(new Date(this.datesList[0]), 'yearMonth')
        } else {
          return this.$d(new Date(this.datesList[0]), 'fullDate')
        }
      }

      if (this.calendarType === 'month') {
        return this.$d(new Date(), 'yearMonth')
      }

      return this.$d(new Date(), 'fullDate')
    },
    todayStr() {
      return this.$formatter.formatDate(new Date())
    },
    timeList() {
      const startTime = this.$settings.startTime
      const endTime = this.$settings.endTime
      const list = []
      const endTimeDate = new Date(`2023-01-01T${endTime}:00+08:00`)
      const tempDate = new Date(`2023-01-01T${startTime}:00+08:00`)

      while (tempDate.getTime() <= endTimeDate.getTime()) {
        list.push(`${this.$formatter.pad(tempDate.getHours())}:${this.$formatter.pad(tempDate.getMinutes())}`)
        tempDate.setTime(tempDate.getTime() + this.interval * 60 * 1000)
      }

      const timeSlotList = []
      for (let i = 0; i < list.length; i++) {
        if (i === list.length - 1) {
          if (list[i] !== endTime) {
            timeSlotList.push({
              start_time: list[i],
              end_time: endTime,
            })
          }
        } else {
          timeSlotList.push({
            start_time: list[i],
            end_time: list[i + 1],
          })
        }
      }

      return timeSlotList
    },
    dailyTableData() {
      if (this.calendarType === 'day') {
        const dateData = this.timetableData.filter(el => el.date === this.calendarValue)
        const tableList = []
        this.timeList.forEach(timeSlot => {
          const startTimeDate = new Date(`2023-01-01T${timeSlot.start_time}:00+08:00`)
          const endTimeDate = new Date(`2023-01-01T${timeSlot.start_time}:00+08:00`)
          endTimeDate.setTime(endTimeDate.getTime() + (this.interval - 1) * 60 * 1000) // e.g. 08:00 - 08:59

          const inTimeData = dateData.filter(el => {
            const tempTimeDate = new Date(`2023-01-01T${el.start_time}:00+08:00`)
            return tempTimeDate.getTime() >= startTimeDate.getTime() && tempTimeDate.getTime() <= endTimeDate.getTime()
          })

          inTimeData.sort((a, b) => {
            const aTimeDate = new Date(`2023-01-01T${a.start_time}:00+08:00`)
            const bTimeDate = new Date(`2023-01-01T${b.start_time}:00+08:00`)
            return aTimeDate.getTime() - bTimeDate.getTime()
          })

          tableList.push(inTimeData)
        })

        return tableList
      }

      return []
    },
    weeklyTableData() {
      if (this.calendarType === 'week') {
        const list = []
        this.datesList.forEach(date => {
          const inDateData = this.timetableData.filter(el => el.date === date)
          inDateData.sort((a, b) => {
            const aTimeDate = new Date(`2023-01-01T${a.start_time}:00+08:00`)
            const bTimeDate = new Date(`2023-01-01T${b.start_time}:00+08:00`)
            return aTimeDate.getTime() - bTimeDate.getTime()
          })
          list.push(inDateData)
        })
        return list
      }

      return []
    },
    monthTableData() {
      if (this.calendarType === 'month') {
        const list = []
        this.timetableData.forEach(item => {
          list.push({
            ...item,
            start: new Date(`${item.date}T${item.start_time}:00+08:00`),
            end: new Date(`${item.date}T${item.end_time}:00+08:00`),
          })
        })
        list.sort((a, b) => a.start.getTime() - b.start.getTime())
        return list
      }

      return []
    },
    displayEmpty() {
      let dailyLen = 0
      this.dailyTableData.forEach(el => {
        dailyLen += el.length
      })
      let weeklyLen = 0
      this.weeklyTableData.forEach(el => {
        weeklyLen += el.length
      })
      return dailyLen === 0 && weeklyLen === 0
    },
  },
  data: () => ({
    icon: {
      mdiChevronLeft,
      mdiChevronRight,
    },
    interval: 60, // minute

    calendarType: 'day', // 'day' | 'week' | 'month'
    calendarValue: '', // YYYY-MM-DD
    datesList: [],
  }),
  methods: {
    setDateList(list) {
      this.datesList = list
    },
    setCurrentValue(value) {
      this.calendarValue = value
    },
    setCalendarType(value) {
      this.calendarType = value
    },
    getDatesList() {
      const currentDate = this.$formatter.convertStrToDate(this.calendarValue)
      let tempDatesList = []
      if (this.calendarType === 'day') {
        tempDatesList = [this.calendarValue]
      } else if (this.calendarType === 'week') {
        const currentWeekday = currentDate.getDay()
        const firstDateOfWeek = this.$formatter.convertStrToDate(this.calendarValue)
        firstDateOfWeek.setDate(currentDate.getDate() - currentWeekday)
        for (let i = 0; i < 7; i++) {
          tempDatesList.push(this.$formatter.formatDate(firstDateOfWeek))
          firstDateOfWeek.setDate(firstDateOfWeek.getDate() + 1)
        }
      } else if (this.calendarType === 'month') {
        const currentMonth = currentDate.getMonth()
        let firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        while (firstDateOfMonth.getMonth() === currentMonth) {
          tempDatesList.push(this.$formatter.formatDate(firstDateOfMonth))
          firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1)
        }
      }
      return tempDatesList
    },
    _onCalendarTypeChanged(type) {
      if (this.loading || this.calendarType === type) {
        return
      }

      this.calendarType = type
      let currentDateStr = this.todayStr
      if (this.$validate.DataValid(this.calendarValue)) {
        currentDateStr = this.calendarValue
      }

      const currentDate = this.$formatter.convertStrToDate(currentDateStr)
      this.calendarValue = this.$formatter.formatDate(currentDate)
      this.datesList = this.getDatesList()
      this.$emit('type-update', this.calendarType)
      this.$emit('dates-update', this.datesList)
    },
    _onDateControlClick(action) {
      if (this.loading) {
        return
      }
      let currentDateStr = this.todayStr
      if (this.$validate.DataValid(this.calendarValue)) {
        currentDateStr = this.calendarValue
      }

      const currentDate = this.$formatter.convertStrToDate(currentDateStr)
      this.datesList = []
      if (this.calendarType === 'day') {
        if (action === 'prev') {
          currentDate.setDate(currentDate.getDate() - 1)
        } else {
          currentDate.setDate(currentDate.getDate() + 1)
        }

        this.calendarValue = this.$formatter.formatDate(currentDate)
      } else if (this.calendarType === 'week') {
        const currentWeekday = currentDate.getDay()
        if (action === 'prev') {
          const lastDateOfPrevWeek = this.$formatter.convertStrToDate(currentDateStr)
          lastDateOfPrevWeek.setDate(currentDate.getDate() - currentWeekday - 1)
          this.calendarValue = this.$formatter.formatDate(lastDateOfPrevWeek)
        } else {
          const lastDateOfNextWeek = this.$formatter.convertStrToDate(currentDateStr)
          lastDateOfNextWeek.setDate(currentDate.getDate() + (6 - currentWeekday) + 7)
          this.calendarValue = this.$formatter.formatDate(lastDateOfNextWeek)
        }
      } else if (this.calendarType === 'month') {
        if (action === 'prev') {
          const dateOfPrevMonth = this.$formatter.convertStrToDate(currentDateStr)
          dateOfPrevMonth.setMonth(currentDate.getMonth() - 1)
          this.calendarValue = this.$formatter.formatDate(dateOfPrevMonth)
          this.$refs.monthCalendar.prev()
        } else {
          const dateOfNextMonth = this.$formatter.convertStrToDate(currentDateStr)
          dateOfNextMonth.setMonth(currentDate.getMonth() + 1)
          this.calendarValue = this.$formatter.formatDate(dateOfNextMonth)
          this.$refs.monthCalendar.next()
        }
      }

      this.datesList = this.getDatesList()
      this.$emit('dates-update', this.datesList)
    },
    _onMonthCalendarDateClick(details) {
      this.calendarValue = details.date;
      this._onCalendarTypeChanged('day');
    },
    _formatWeekday(d) {
      return this.$d(new Date(d), 'weekday')
    },
    _formateShortDay(d) {
      const dDate = this.$formatter.convertStrToDate(d)
      return `${this.$formatter.pad(dDate.getDate())} - ${this.$formatter.pad(dDate.getMonth() + 1)}`
    },
    _navToDetails(id) {
      const userData = this.getCurrentUserData()
      if (
        !userData ||
        (cannotAccessList['ClassDetails'] &&
          cannotAccessList['ClassDetails'].length &&
          cannotAccessList['ClassDetails'].includes(userData.user_type))
      ) {
        return
      }

      this.$router.push({ name: 'ClassDetails', params: { id: id } })
    },
    _displayCalendarWeekday(d) {
      return this.$d(new Date(d.date), 'weekday');
    },
    _displayCalendarDay(d) {
      return new Date(d.date).getDate();
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep.custom-timetable {
  & > .custom-timetable__body {
    overflow-x: auto;

    & .custom-timetable__col {
      width: 200px;
      min-width: 200px;
      padding-left: 8px;
      padding-right: 8px;
    }

    & .custom-timetable__col__header {
      font-size: 0.875rem;
      color: var(--v-colorBlack-base);
      text-align: center;
      padding-top: 16px;
      padding-bottom: 16px;
      display: block;
    }
  }

  & .custom-timetable-card {
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    & .custom-timetable-card__top {
      padding: 5px 10px;
      color: #ffffff;
      font-size: 0.75rem;
      line-height: 1.5;
    }

    & .custom-timetable-card__bot {
      color: var(--v-colorBlack-base);
      padding: 10px;
      background: #eeeeee;

      & > .course-name {
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 600;
        margin-bottom: 10px;
      }

      & > .course-info-list {
        padding: 0;
        list-style: none;

        & > li {
          position: relative;
          color: var(--v-colorBlack-base);
          font-size: 10px;
          margin: 5px 0;
        }
      }

      & > .student-attendance-list {
        padding: 0;
        list-style: none;
        margin-top: 10px;

        & > li {
          position: relative;
          color: var(--v-colorBlack-base);
          font-size: 10px;
          margin: 5px 0;
          display: flex;
          align-items: center;
          line-height: 1.5;

          & > .attend-check {
            width: 10px;
            min-width: 10px;
            height: 10px;
            border-radius: 2px;
            background: #a7a7a7;
            margin-right: 8px;

            &.attend {
              background: #27c712;
            }

            &.absent {
              background: var(--v-red-base);
            }
          }
        }
      }
    }
  }
}

::v-deep.v-calendar {
  & .v-event {
    height: auto !important;
    border-radius: 5px !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px !important;
  }

  & [role="columnheader"],
  & [role="cell"] {
    min-width: 170px !important;
  }

  & [role="cell"] {
    min-height: 100px !important;

    &.v-outside {
      & .v-calendar-weekly__day-label>.v-btn {
        color: rgb(88 85 93 / 30%) !important;
      }
    }

    &.v-present {
      & .v-calendar-weekly__day-label>.v-btn {
        background-color: #efefef !important;
      }
    }
  }

  & [role="columnheader"] {
    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    font-family: "Rubik", "Noto Sans HK", sans-serif !important;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--v-colorBlack-base) !important;
  }
}
</style>