<template>
  <v-container fluid>
    <PageHeaderSlot>
      <template slot="top-right-slot" v-if="canCreate">
        <v-btn text depressed class="primary ml-2" :to="{ name: 'CreateClass' }">
          <v-icon size="20" class="mr-3">{{ icon.mdiPlusCircleOutline }}</v-icon>
          {{ $t('createLesson') }}
        </v-btn>
      </template>
    </PageHeaderSlot>

    <v-card class="custom-calendar custom-table">
      <v-toolbar flat class="custom-table-toolbar" height="auto">
        <v-toolbar-items class="toolbar-left-items flex-grow-1">
          <FormDatePicker
            :dateValue.sync="filter.date"
            dense
            hideDetails
            customInputClass="mr-3 mt-2 mb-3 search-input-field"
            placeholder="table.searchDate"
            :readonly="tableLoading"
            @changed="onSearchDateChange($event)"
          />

          <FormSelect
            :fieldValue.sync="filter.course"
            dense
            hideDetails
            class="mr-3 mt-2 mb-3 search-input-field"
            placeholder="course"
            :options="courseOptions"
            @changed="search()"
            :readonly="tableLoading"
          />

          <FormSelect
            :fieldValue.sync="filter.tutor"
            dense
            hideDetails
            class="mr-3 mt-2 mb-3 search-input-field"
            placeholder="courseTutor"
            :options="tutorOptions"
            @changed="search()"
            :readonly="tableLoading"
          />
        </v-toolbar-items>

        <v-spacer></v-spacer>

        <v-toolbar-items class="toolbar-right-items">
          <v-btn text small color="error" @click="clearSearch()" :class="{ 'pointer-loading': tableLoading }">
            <v-icon class="mr-1" color="error" size="18"> {{ icon.mdiClose }} </v-icon>
            {{ $t('table.clearSearch') }}
          </v-btn>

          <v-btn text small color="primary" @click="refreshData()" :class="{ 'pointer-loading': tableLoading }">
            <v-icon class="mr-1" color="primary" size="18"> {{ icon.mdiRefresh }} </v-icon>
            {{ $t('table.reloadTable') }}
          </v-btn>

          <v-btn
            text
            small
            color="success"
            target="_blank"
            rel="noreferrer noopenner"
            @click="exportData()"
            :class="{ 'pointer-loading': tableLoading }"
          >
            <v-icon class="mr-1" color="success" size="18"> {{ icon.mdiFileTableOutline }} </v-icon>
            {{ $t('table.exportReport') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <CustomTimetable
        ref="timetable"
        :loading="tableLoading"
        :timetableData="timetableData"
        @dates-update="getTimetable($event)"
        @type-update="updateCalendarType($event)"
      ></CustomTimetable>
    </v-card>
  </v-container>
</template>

<script>
import {
  mdiClose,
  mdiRefresh,
  mdiFileTableOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiPlusCircleOutline,
} from '@mdi/js'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'
import FormSelect from '@/components/formField/FormSelect.vue'
import CustomTimetable from '@/components/CustomTimetable.vue'
import ExcelMixin from '@/mixins/ExcelMixin.vue'
import { cannotAccessList } from '@/assets/cannotAccessList'

export default {
  name: 'Timetable',
  mixins: [ExcelMixin],
  components: {
    FormDatePicker,
    FormSelect,
    CustomTimetable,
  },
  computed: {
    query() {
      return this.$route.query
    },
  },
  data: () => ({
    icon: {
      mdiClose,
      mdiRefresh,
      mdiFileTableOutline,
      mdiChevronLeft,
      mdiChevronRight,
      mdiPlusCircleOutline,
    },
    filter: {
      date: '',
      course: '',
      tutor: '',
      dateList: [],
      calendarType: '',
    },

    courseOptions: [],
    tutorOptions: [],

    tableLoading: false,
    timetableData: [],

    exportLoading: false,

    canCreate: true,
  }),
  methods: {
    async getPayload() {
      let payload = {
        join_tutor_data: true,
      }

      const selectedSchool = await this.getUserSelectedSchool()
      if (this.$validate.DataValid(selectedSchool)) {
        payload.center_id = selectedSchool
      }

      const filterItems = []
      if (this.$validate.DataValid(this.filter.dateList)) {
        if (this.filter.dateList.length > 1) {
          filterItems.push({ key: 'start_date', value: this.filter.dateList[0] })
          filterItems.push({ key: 'end_date', value: this.filter.dateList[this.filter.dateList.length - 1] })
        } else {
          filterItems.push({ key: 'date', value: this.filter.dateList[0] })
          payload['join_student_data'] = true
        }
      }

      if (this.$validate.DataValid(this.filter.tutor)) {
        filterItems.push({ key: 'staff_id', value: parseInt(this.filter.tutor) })
      }

      if (this.$validate.DataValid(this.filter.course)) {
        filterItems.push({ key: 'course_id', value: parseInt(this.filter.course) })
      }

      if (this.$validate.DataValid(filterItems)) {
        payload.filter_item = filterItems
      }

      return payload
    },
    async getTimetable(dateList, regenUrl = true) {
      if (this.$validate.DataValid(dateList)) {
        this.filter.dateList = dateList
      }

      if (regenUrl === true) {
        this.regenerateURL()
      }

      this.tableLoading = true
      try {
        const payload = await this.getPayload()
        const { data } = await this.$Fetcher.GetLessons(payload)
        this.timetableData = data.map(el => {
          const studentData = []
          for (let i = 0; i < el.attendance_list.length; i++) {
            if (studentData.length < 3) {
              const student = el.attendance_list[i];
              if (this.$validate.DataValid(student) && (student.status === 'pending' || student.status === 'attend' || student.status === 'absent')) {
                studentData.push({
                  gender: student.gender,
                  name: student.student_name,
                  attendance: student.status,
                })
              }
            } else {
              break;
            }
          }

          return {
            id: el.id,
            date: el.date,
            tutor: this.$validate.DataValid(el.tutor_data) ? el.tutor_data.name : '',
            current_student_num: el.current_student_num || 0,
            max_student: el.max_student,
            color: el.color,
            grade: el.grade,
            course_name: el.course_name,
            start_time: el.start_time,
            end_time: el.end_time,
            classroom: this.$validate.DataValid(el.classroom_data) ? el.classroom_data.name : '',
            student_data: studentData,
          }
        })
      } catch (err) {
        this.$common.error(err)
        this.timetableData = []
      } finally {
        this.tableLoading = false
      }
    },
    updateCalendarType(val) {
      this.filter.calendarType = val
    },
    onSearchDateChange(val) {
      if (this.tableLoading) {
        return
      }

      const todayStr = this.$formatter.formatDate(new Date())
      if (!this.$validate.DataValid(val)) {
        this.$refs.timetable.setCurrentValue(todayStr)
      } else {
        this.$refs.timetable.setCurrentValue(val)
      }

      this.$refs.timetable.setCalendarType('day')
      this.filter.calendarType = 'day'
      const dateList = this.$refs.timetable.getDatesList()
      this.$refs.timetable.setDateList(dateList)
      this.getTimetable(dateList)
    },
    search() {
      if (this.tableLoading) {
        return
      }
      this.getTimetable()
    },
    clearSearch() {
      if (this.tableLoading) {
        return
      }
      this.filter.course = ''
      this.filter.tutor = ''
      this.filter.date = ''
      this.onSearchDateChange()
    },
    refreshData() {
      if (this.tableLoading) {
        return
      }
      this.onSearchDateChange(this.filter.date)
    },
    async exportData() {
      if (this.exportLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.exportLoading = true

      try {
        const header = {
          date: this.$t('lessonDate'),
          course_name: this.$t('courseName'),
          start_time: this.$t('courseStartTime'),
          end_time: this.$t('courseEndTime'),
          grade: this.$t('grade'),
          tutor: this.$t('tutor'),
          classroom: this.$t('classroom'),
          student_num: this.$t('personCount'),
        }
        const payload = await this.getPayload()
        const { data } = await this.$Fetcher.GetLessons(payload)
        const exportData = data.map(el => {
          return {
            date: el.date,
            course_name: el.course_name,
            start_time: el.start_time,
            end_time: el.end_time,
            grade: this.$grade[el.grade],
            tutor: el.tutor_data ? el.tutor_data.name : '',
            classroom: el.classroom_data ? el.classroom_data.name : '',
            student_num: `${el.current_student_num} / ${el.max_student}`,
          }
        })

        this.exportExcel(
          header,
          exportData,
          this.$t('menu.timetable'),
          `timetable_${this.$formatter.formatDate(new Date()).replace(/-/g, '')}.xlsx`,
        )
      } catch (err) {
        this.$common.error(err)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.exportFail',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      } finally {
        this.exportLoading = false
      }
    },

    async getTutorOptions() {
      try {
        let payload = {}
        const selectedSchool = await this.getUserSelectedSchool()
        if (this.$validate.DataValid(selectedSchool)) {
          payload.center = [selectedSchool]
        }

        const { data } = await this.$Fetcher.GetTutors(payload)
        this.tutorOptions = data.map(el => {
          return {
            value: el.id,
            text: el.name,
          }
        })
      } catch {
      } finally {
        await this.getCourseOptions()
      }
    },
    async getCourseOptions() {
      try {
        let payload = {
          join_category_data: true,
        }
        const selectedSchool = await this.getUserSelectedSchool()
        if (this.$validate.DataValid(selectedSchool)) {
          payload.center_id = selectedSchool
        }

        const { data } = await this.$Fetcher.GetCourses(payload)
        this.courseOptions = data.map(el => {
          return {
            value: el.id,
            text: this.$formatter.generateCourseTitle(el),
          }
        })
      } catch {}
    },

    async getQuery() {
      const todayStr = this.$formatter.formatDate(new Date())
      if (Object.keys(this.query).length > 0) {
        if (this.$validate.DataValid(this.query.date) && this.$validate.regexDateStr(this.query.date)) {
          this.filter.date = this.query.date
        }

        if (this.$validate.DataValid(this.query.start_date) && this.$validate.regexDateStr(this.query.start_date)) {
          this.$refs.timetable.setCurrentValue(this.query.start_date)
        } else {
          this.$refs.timetable.setCurrentValue(todayStr)
        }

        if (this.query.type === 'week') {
          this.$refs.timetable.setCalendarType('week')
        } else if (this.query.type === 'month') {
          this.$refs.timetable.setCalendarType('month')
        } else {
          this.$refs.timetable.setCalendarType('day')
        }

        this.filter.dateList = this.$refs.timetable.getDatesList()
        this.$refs.timetable.setDateList(this.filter.dateList)

        if (this.$validate.DataValid(this.query.course) && this.$validate.regexNumber(this.query.course)) {
          this.filter.course = parseInt(this.query.course)
        }

        if (this.$validate.DataValid(this.query.tutor) && this.$validate.regexNumber(this.query.tutor)) {
          this.filter.tutor = parseInt(this.query.tutor)
        }
      } else {
        this.$refs.timetable.setCurrentValue(todayStr)
        this.filter.dateList = this.$refs.timetable.getDatesList()
        this.$refs.timetable.setDateList(this.filter.dateList)
      }

      await this.getTimetable(this.filter.dateList, false)
      await this.getTutorOptions()
    },

    regenerateURL() {
      const todayStr = this.$formatter.formatDate(new Date())
      const q = {
        date: this.filter.date,
        start_date: this.filter.dateList[0] && this.filter.dateList[0] !== todayStr ? this.filter.dateList[0] : '',
        type: this.filter.calendarType !== 'day' ? this.filter.calendarType : '',
        course: this.filter.course,
        tutor: this.filter.tutor,
      }

      const newQuery = this.$common.diffQuery(q, this.query)
      if (newQuery) {
        this.$router.replace({ query: newQuery })
      }
    },

    async handleSiteLoaded() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: Timetable')
      this.getQuery()
    },
  },

  destroyed() {
    window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  mounted() {
    this.tableLoading = true
    window.addEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  created() {
    const userType = this.getUserType()
    if (
      !userType ||
      (cannotAccessList['CreateClass'] &&
        cannotAccessList['CreateClass'].length &&
        cannotAccessList['CreateClass'].includes(userType))
    ) {
      this.canCreate = false
    }
  },
}
</script>
